@keyframes fade-in-bottom-left {
	from {
		opacity: 0;
		transform: translate3d(-100%, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-bottom-right {
	from {
		opacity: 0;
		transform: translate3d(100%, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-down {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-left {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-right {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-top-left {
	from {
		opacity: 0;
		transform: translate3d(-100%, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-top-right {
	from {
		opacity: 0;
		transform: translate3d(100%, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-up {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fade-out-bottom-left {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(-100%, 100%, 0);
	}
}

@keyframes fade-out-bottom-right {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(100%, 100%, 0);
	}
}

@keyframes fade-out-down {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes fade-out-left {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes fade-out-right {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes fade-out-top-left {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(-100%, -100%, 0);
	}
}

@keyframes fade-out-top-right {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(100%, -100%, 0);
	}
}

@keyframes fade-out-up {
	from {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes roll-in-left {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes roll-in-right {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes roll-out-left {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
	}
}

@keyframes roll-out-right {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
	}
}

@keyframes shake-horizontal {

	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transform: translate3d(7px, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-7px, 0, 0);
	}
}

@keyframes shake-vertical {

	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transform: translate3d(0, 7px, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(0, -7px, 0);
	}
}

@keyframes shake-diagonally {

	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		-webkit-transform: translate3d(7px, 7px, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: translate3d(-7px, -7px, 0);
	}
}

@keyframes shake-diagonally {

	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transform: translate3d(7px, 7px, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-7px, -7px, 0);
	}
}

@keyframes shake-i-diagonally {

	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		-webkit-transform: translate3d(7px, -7px, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: translate3d(-7px, 7px, 0);
	}
}

@keyframes shake-i-diagonally {

	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transform: translate3d(7px, -7px, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-7px, 7px, 0);
	}
}

@keyframes slide-in-down {
	from {
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slide-in-left {
	from {
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}


@keyframes slide-in-right {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slide-in-up {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slide-out-down {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slide-out-left {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes slide-out-right {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes slide-out-up {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
	}
}

@keyframes zoom-in-down {
	from {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	70% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 20px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-in-left {
	from {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	70% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(10px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-in-right {
	from {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	70% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(-10px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-in-up {
	from {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	70% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -20px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-in {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoom-out-down {
	40% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -20px, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	to {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-out-left {
	40% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(20px, 0, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	to {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-out-right {
	40% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(-20px, 0, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	to {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-out-up {
	40% {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 20px, 0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	to {
		opacity: 0;
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}

@keyframes zoom-out {
	to {
		opacity: 1;
	}

	50% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	from {
		opacity: 0;
	}
}

@keyframes blur-in {
	from {
		opacity: 0;
		filter: blur(4px);
	}

	to {
		opacity: 1;
		filter: blur(0);
	}
}

@keyframes blur-out {
	from {
		opacity: 1;
		filter: blur(0);
	}

	to {
		opacity: 0;
		filter: blur(4px);
	}
}

@keyframes flash {

	0%,
	50%,
	100% {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

@keyframes throb {
	0% {
		transform: scale(1);
	}

	12% {
		transform: scale(1.5);
	}

	27% {
		transform: scale(1);
	}

	41% {
		transform: scale(1.5);
	}

	70% {
		transform: scale(1);
	}
}

@keyframes i-throb {
	0% {
		transform: scale(1);
	}

	12% {
		transform: scale(0.5);
	}

	27% {
		transform: scale(1);
	}

	41% {
		transform: scale(0.5);
	}

	70% {
		transform: scale(1);
	}
}

@keyframes swivel-vertical {
	from {
		transform: rotateX(0);
	}

	to {
		transform: rotateX(360deg);
	}
}

@keyframes swivel-vertical-double {
	from {
		transform: rotateX(0);
	}

	to {
		transform: rotateX(720deg);
	}
}

@keyframes swivel-horizontal {
	from {
		transform: rotateY(0);
	}

	to {
		transform: rotateY(360deg);
	}
}

@keyframes swivel-horizontal-double {
	from {
		transform: rotateY(0);
	}

	to {
		transform: rotateY(720deg);
	}
}

@keyframes shrink-left {
	to {
		transform: scale(0);
		transform-origin: left;
	}
}

@keyframes shrink-top {
	from {
		transform: scale(0);
		transform-origin: top;
	}
}

@keyframes shrink-bottom {
	to {
		transform: scale(0);
		transform-origin: bottom;
	}
}

@keyframes shrink-right {
	from {
		transform: scale(0);
		transform-origin: right;
	}
}