@import url('https://fonts.googleapis.com/css2?family=Lato&family=Playfair+Display&family=Poppins&display=swap');

// colors
$cream: #eae5d6;
$yellow: #fab232;
$light-orange: #F99931;
$orange: #f58432;
$black: #343434;
$grey: #585757;
$blue: #244C60;

// gradient
$gradient: linear-gradient(90deg, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, 1) 50%, rgba(245, 132, 50, 1) 100%);
$buy-gradient: linear-gradient(90deg, rgb(241, 186, 91) 0%, rgb(244, 169, 89) 50%, rgb(250, 140, 62) 100%);

$transparent-gradient: linear-gradient(90deg, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, .7) 50%, rgba(245, 132, 50, 1) 100%);
$header-gradient: radial-gradient(circle, rgba(250, 178, 50, 1) 0%, rgba(249, 153, 49, 1) 50%, rgba(245, 132, 50, 1) 100%);
$title-gradient: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(234, 229, 214, 1) 69%, rgba(250, 178, 50, 1) 100%);
$faded-gradient: linear-gradient(90deg, rgba(250, 178, 50, .7) 0%, rgba(249, 153, 49, .7) 50%, rgba(245, 132, 50, 1) 100%);
$f-gradient: linear-gradient(90deg, rgba(250, 178, 50, .5) 0%, rgba(249, 153, 49, .5) 50%, rgba(245, 132, 50, .5) 100%);

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;

// font-family
$header-font: 'Roboto', sans-serif;
$font-2: 'Lato', sans-serif;
$font-3: 'Playfair Display', serif;
$font-4: 'Poppins', sans-serif;