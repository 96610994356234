@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

li {
	list-style-type: none;
}

a {
	text-decoration: none;
	color: inherit;
	// font-family: Font2;
	// font-weight: bold;
}

p,
li,
a {
	font-family: Font3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $header-font;
}



section {
	padding: 3% 0;
	overflow: hidden;
}


.section-title {
	text-align: center;
	padding-bottom: 30px;

	h2 {
		font-size: 3vw;
		font-weight: bold;
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		color: $blue;
		font-family: $font-3;

		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 120px;
			height: 1px;
			background: rgba($color: #000, $alpha: 0.1);
			bottom: 1px;
			left: calc(50% - 60px);
		}

		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 40px;
			height: 3px;
			background: $yellow;
			bottom: 0;
			left: calc(50% - 20px);
		}
	}

	p {
		margin-bottom: 0;
	}
}

.main {
	display: flex;
	justify-content: flex-start;
	position: relative;
}

* {
	transition: .5s all ease;
	scroll-behavior: smooth;
}

p {
	font-family: $font-2;
}

.inside-heading {
	border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
	padding-bottom: 2%;
	@include Headline;

	.key-content {
		p {
			font-size: 1.2vw;
			line-height: 180%;
			font-family: $font-2;
			color: rgba($color: #000, $alpha: 0.7);
			margin: 0;
			text-transform: capitalize;
			display: flex;
			align-items: center;

			svg {
				width: 10px;
				height: 10px;
				fill: rgba($color: #000, $alpha: 0.7);
			}
		}
	}

	.benefits-content {

		ul {
			li {
				font-size: 1.2vw;
				line-height: 180%;
				color: rgba($color: #000, $alpha: 0.7);
				margin: 0;
				// text-transform: capitalize;
				list-style-type: circle;
				font-family: $font-2;
				margin-bottom: 1%;
			}
		}
	}
}


/*--------------------------------------------------------------
# Up Arrow
--------------------------------------------------------------*/
.uparrow {
	background: #fff;
	position: fixed;
	bottom: 5%;
	right: 5%;
	width: 50px;
	height: 50px;
	z-index: 1000;
	// filter: drop-shadow(2px 2px 2px #fff);
	cursor: pointer;
	animation: slide-in-down 1s;
	transition: 1s all ease;
	border: 3px solid $blue;


	svg {
		fill: $blue;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/
header {
	background: #fff;
	top: 3%;
	left: 0;
	width: 100%;
	position: fixed;
	z-index: 3000;
	padding: .7% 5%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: $box-shadow;
	transition: .5s all ease-in-out;

	.menu {
		width: 30px;
		height: 30px;
		margin-right: 10%;
		fill: $blue;
	}

	.header-logo {
		width: 20%;

		img {
			width: 100%;
			object-fit: contain;
		}
	}

	.header-links {
		width: 55%;
		display: flex;
		justify-content: center;
		align-items: center;

		.h-l {
			font-size: 1.1vw;
			text-transform: uppercase;
			padding: 1%;
			margin-right: 5%;
			font-family: $font-2;
			font-weight: bold;

			&:hover {
				transform: scale(1.2);
				border-top: 1px solid $blue;
				border-bottom: 1px solid $blue;
				border-radius: 10px 0 10px 0;
			}

			&:last-child {
				background: $blue;
				color: #fff;
				padding: 1% 2%;
				border-radius: 10px 0 10px 0;
			}
		}

		.prod {
			position: relative;
			width: 20%;

			p {
				margin: 0;
				font-size: 1.1vw;
				text-transform: uppercase;
				font-family: $font-2;
				font-weight: bold;
				padding: 1%;
				margin-right: 3%;
				position: relative;
				cursor: pointer;

				svg {
					fill: $blue;
					width: 12px;
					height: 12px;
					position: absolute;
					right: 25%;
					bottom: 5%;
				}
			}

			.prod-dropdown {
				position: absolute;
				top: 280%;
				left: -20%;
				// box-shadow: 1px 2px 1px rgba($color: #fff, $alpha: 0.5);
				display: flex;
				flex-direction: column;
				width: 120%;
				animation: slide-in-up .5s 1;
				background: transparent;

				.prod-link {
					text-transform: capitalize;
					font-size: 1.2vw;
					color: $blue;
					padding: 10%;
					font-family: $font-2;
					font-weight: bold;
					color: $blue;
					box-shadow: inset 2px 2px 20px rgba($color: $blue, $alpha: 0.7);
					margin: 1%;
					border-radius: 10px;
					background: #fff;
					text-align: center;

					&:hover {
						transform: translateX(10px);
						border: none;
					}
				}
			}
		}
	}

	.header-links-mobile {
		animation: slide-in-right 1s;
		position: fixed;
		top: 0;
		right: 0;
		width: 50%;
		height: 100%;
		background: $blue;
		box-shadow: $box-shadow;
		display: flex;
		flex-direction: column;
		padding: 20% 5%;
		color: #fff;

		.svg {
			width: 40px;
			height: 40px;
			background: #fff;
			border-radius: 10px;
			top: 2%;
			right: 5%;
			position: absolute;

			svg {
				width: 20px;
				height: 20px;
				fill: $blue;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.h-l {
			font-size: 3.5vw;
			text-transform: uppercase;
			padding: 1%;
			margin-bottom: 10%;
			font-family: $font-2;
			font-weight: bold;
			display: flex;
			flex-direction: column;
			letter-spacing: 1px;

			&:hover {
				transform: scale(1.2);
				border-top: 1px solid $blue;
				border-bottom: 1px solid $blue;
				border-radius: 10px 0 10px 0;
			}

		}

		.prod {
			position: relative;
			// width: 20%;
			margin-bottom: 10%;

			p {
				margin: 0;
				font-size: 3.5vw;
				text-transform: uppercase;
				font-family: $font-2;
				font-weight: bold;
				padding: 1%;
				margin-right: 3%;
				position: relative;
				cursor: pointer;
				display: flex;
				letter-spacing: 1px;

				svg {
					fill: #fff;
					width: 15px;
					height: 15px;
					position: absolute;
					right: 45%;
					bottom: -2%;
				}
			}

			.prod-dropdown {
				position: absolute;
				top: 150%;
				left: 0;
				// box-shadow: 1px 2px 1px rgba($color: #000, $alpha: 0.1);
				// background: #fff;
				display: flex;
				flex-direction: column;
				width: 100%;
				animation: slide-in-up .5s 1;

				.prod-link {
					text-transform: capitalize;
					font-size: 3.4vw;
					color: $blue;
					padding: 5%;
					margin: 2% 1%;
					border-radius: 5px;
					font-family: $font-2;
					font-weight: bold;
					box-shadow: inset 2px 2px 20px rgba($color: $blue, $alpha: 0.7);
					background: #fff;

					&:hover {
						transform: translateX(10px);
						border: none;
						border-radius: 0;
					}
				}
			}
		}

	}

}

/*--------------------------------------------------------------
# Banner
--------------------------------------------------------------*/
.banner {
	background: rgb(112, 152, 183);
	background: linear-gradient(90deg, rgba(112, 152, 183, 1) 0%, rgba(36, 76, 96, 1) 56%);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	.banner-shape {
		width: 0;
		height: 0;
		border: 750px solid transparent;
		border-top: 0;
		border-left: 1px solid #fff;
		border-bottom: 1000px solid #fff;
		position: absolute;
		right: 0;
		top: 5%;
		rotate: 180deg;
	}

	.banner-shape2 {
		width: 0;
		height: 0;
		border: 350px solid transparent;
		border-top: 0;
		border-left: 1px solid #fff;
		border-bottom: 200px solid #fff;
		position: absolute;
		right: 0;
		top: 0;
		rotate: 180deg;
	}

	.banner-text {
		width: 50%;
		padding: 5%;
		color: #fff;
		margin-top: 10%;
		font-family: $font-4;


		span {
			margin: 0;
			font-size: 2vw;
			margin-top: 5%;
			font-style: italic;
			// margin-left: %;
			font-family: $font-3;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				width: 230%;
				height: 1px;
				background: #fff;
				top: 50%;
				left: 230%;
				transform: translate(-50%, -50%);
			}
		}

		h1 {
			font-size: 5vw;
			text-transform: uppercase;
			text-shadow: 2px 2px 1px rgba($color: #fff, $alpha: 0.3);
			font-family: $font-4;
			margin: 0;
		}

		p {
			margin: 0;
			font-size: 1.2vw;
			line-height: 150%;
			font-family: $font-2;
		}

		a {
			border: 2px solid #fff;
			padding: 3% 5%;
			font-size: 1.5vw;
			margin-top: 5%;
			display: block;
			border-radius: 40px;
			width: 30%;
			text-align: center;
			text-transform: capitalize;
			font-family: $font-2;

			&:hover {
				background: #fff;
				color: $blue;
			}
		}
	}

	.banner-img {
		width: 50%;
		padding: 0 5% 4% 5%;
		z-index: 200;
		position: relative;

		.banner-img-content {
			width: 25%;
			position: absolute;
			background: $blue;
			padding: 5%;
			// border-radius: 50%;
			box-shadow: 2px 2px 15px rgba($color: $blue, $alpha: 0.7);
			border: 2px solid rgba($color: #fff, $alpha: 0.9);

			img {
				width: 100%;
				object-fit: contain;
				margin: auto;
				display: block;
				cursor: pointer;
				filter: drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.2));
			}
		}

		.img1 {
			top: -250%;
			left: 10%;
			z-index: -1;

			&:hover {
				width: 55%;
				z-index: 30000;
				top: -280%;
				right: 10%;

				img {
					transform: scale(1.1);
					rotate: 360deg;
				}
			}

		}

		.img2 {
			right: 4%;
			top: -250%;



			&:hover {
				width: 55%;
				z-index: 30000;
				top: -230%;
				right: 10%;

				img {
					rotate: 360deg;
					transform: scale(1.1);
				}
			}
		}

		.img3 {
			right: 29%;
			top: 120%;
			z-index: 2;

			&:hover {
				width: 55%;
				z-index: 30000;
				top: -280%;

				img {
					transform: scale(1.1);
					rotate: 360deg;
				}
			}
		}


	}
}

/*--------------------------------------------------------------
# Products
--------------------------------------------------------------*/
.products {
	padding: 3% 3% 1% 3%;
	position: relative;


	.products-body {
		@include flex;
		align-items: stretch;
		justify-content: flex-start;

		.products-card {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			box-shadow: 2px 2px 15px rgba($color: #000, $alpha: 0.3);
			margin: 1%;
			border: 1px solid rgba($color: #000, $alpha: 0.1);
			width: calc(100% / 2);
			// height: 50vh;

			.products-img {
				// width: 50%;
				height: 100%;
				background: $blue;
				// margin-left: 1%;
				// padding: 5%;
				// position: relative;
				// flex-grow: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 95%;
					// width: 90%;
					display: block;
					// position: absolute;
					// top: 50%;
					// left: 50%;
					// transform: translate(-50%, -50%);
					margin: 2% auto;
					object-fit: contain;
				}
			}

			.products-text {
				// width: 50%;
				height: 83%;
				padding: 5%;
				display: flex;
				flex-direction: column;
				background: #fff;

				h3 {
					font-family: $font-3;
					font-size: 2.2vw;
					margin: 0;
					text-align: center;
					margin-bottom: 3%;
				}

				p {
					font-size: 1.05vw;
					line-height: 150%;
					// display: none;
					// text-align: center;
					flex-grow: 1;
				}

				.product-text-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					// position: absolute;
					// bottom: -15%;
					// left: 5%;
					// width: 90%;

					a {

						font-family: $font-4;
						background: $blue;
						color: #fff;
						padding: 3% 5%;
						text-align: center;
						margin-top: 5%;
						display: block;
						display: flex;
						justify-content: center;
						align-items: center;
						width: calc(100% / 2);
						margin: 5% 1% 0 1%;
						box-shadow: $box-shadow;

						span {
							// display: none;
							margin: 5% 0;
							font-size: 1vw;
						}

						svg {
							width: 20px;
							height: 20px;
							fill: #fff;
							margin-right: 5%;
						}

					}
				}
			}

		}
	}
}

/*--------------------------------------------------------------
# Order
--------------------------------------------------------------*/
.order {
	padding: 0 3% 3% 3%;

	.order-body {
		background: $blue;

		display: flex;
		align-items: center;
		position: relative;

		img {
			width: 50%;
			height: 100%;
			background: #fff;
			object-fit: cover;
			// border-radius: 0 300px 300px 0;
			// position: absolute;
			// top: -1%;
			// left: -5%;
			// padding: 3% 5% 3% 10%;
			// box-shadow: $box-shadow;
			// display: none;
		}

		form {
			display: flex;
			flex-direction: column;
			color: #fff;
			width: 50%;
			margin-left: auto;
			padding: 5%;

			.input {
				display: flex;
				align-items: center;

				input {
					width: calc(100% / 2);
					background: transparent;
					border: none;
					border-bottom: 2px solid #fff;
					padding: 2% 3%;
					font-size: 1.3vw;
					box-shadow: inset 2px 2px 25px rgba($color: #fff, $alpha: 0.3);
					margin-bottom: 2%;
					border-radius: 40px;
					outline: none;
					color: #fff;


					&:first-child {
						margin-right: 5%;
					}

					&::placeholder {
						color: #fff;
					}

				}

				input[type="email"] {
					&::placeholder {
						text-transform: capitalize;
					}
				}
			}

			select {
				background: transparent;
				border: none;
				border-bottom: 2px solid #fff;
				color: #fff;
				padding: 2% 2%;
				font-size: 1.3vw;
				box-shadow: inset 2px 2px 25px rgba($color: #fff, $alpha: 0.3);
				margin-bottom: 2%;
				border-radius: 40px;
				outline: none;

				&::placeholder {
					color: #fff;
				}

				option {
					background: $blue;
				}
			}

			button {
				padding: 2% 2%;
				background: #fff;
				border: none;
				font-size: 1.3vw;
				color: $blue;
				box-shadow: 3px 5px 2px rgba($color: #fff, $alpha: 0.7);
				border-radius: 20px;
				margin-top: 2%;
				cursor: pointer;

				&:hover {
					box-shadow: 2px 2px 2px rgba($color: #fff, $alpha: 0.3);
				}
			}
		}
	}
}

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
.form {
	position: fixed;
	background: $blue;
	width: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 4000;
	width: 35%;
	box-shadow: $box-shadow;
	border-radius: 5px;
	animation: blur-in 1s;

	img {
		width: 100%;
		object-fit: contain;
	}

	svg {
		width: 20px;
		height: 20px;
		fill: #fff;
		position: absolute;
		right: 5%;
		top: 5%;
		cursor: pointer;

		&:hover {
			transform: scale(1.1);
		}
	}

	h3 {
		font-size: 3vw;
		color: #fff;
		font-family: $font-3;
		text-align: center;
		// margin: 0;
		letter-spacing: 1px;
		font-weight: bold;
		// margin: 3% 0;
	}

	form {
		display: flex;
		flex-direction: column;
		color: #fff;
		margin-left: auto;
		padding: 0 10% 10% 10%;


		input {
			width: 95%;
			background: transparent;
			border: none;
			border-bottom: 2px solid #fff;
			padding: 2% 3%;
			font-size: 1.3vw;
			// box-shadow: inset 2px 2px 25px rgba($color: #fff, $alpha: 0.3);
			margin-bottom: 5%;
			// border-radius: 40px;
			outline: none;
			color: #fff;


			&:first-child {
				margin-right: 5%;
			}

			&::placeholder {
				color: #fff;
			}

		}

		input[type="email"] {
			&::placeholder {
				text-transform: capitalize;
			}
		}

		select {
			background: transparent;
			border: none;
			border-bottom: 2px solid #fff;
			color: #fff;
			padding: 2% 2%;
			font-size: 1.3vw;
			outline: none;
			margin-bottom: 5%;

			// box-shadow: inset 2px 2px 25px rgba($color: #fff, $alpha: 0.3);
			// border-radius: 40px;
			option {
				background: $blue;
			}

			&::placeholder {
				color: #fff;
			}
		}

		button {
			padding: 2% 2%;
			background: #fff;
			border: none;
			font-size: 1.3vw;
			color: $blue;
			box-shadow: 3px 5px 2px rgba($color: #fff, $alpha: 0.7);
			cursor: pointer;

			&:hover {
				box-shadow: 2px 2px 2px rgba($color: #fff, $alpha: 0.3);
			}
		}
	}
}

.product-zoom {
	width: 50%;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
	// box-shadow: $box-shadow;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-direction: column;
	padding: 5% 2%;
	width: 15%;
	background: $blue;
	position: sticky;
	top: 10%;
	left: 0;
	height: 100vh;
	scroll-behavior: smooth;

	a {
		margin-bottom: 15%;
		text-transform: capitalize;
		color: #fff;
		font-family: $font-4;
		font-size: 1.2vw;
		display: flex;
		align-items: center;

		svg {
			width: 12px;
			height: 12px;
			fill: #fff;
			margin-left: auto;
			margin-right: 5%;
			display: none;
		}

		&:hover {
			transition: .5s all ease;
			transform: translateX(10px);

			svg {
				display: block;
			}

		}
	}
}


.main-body {
	width: 75%;
	margin-left: auto;
	margin-top: 7%;
	padding: 2% 2% 2% 0;
}

/*--------------------------------------------------------------
# overview
--------------------------------------------------------------*/
.overview {
	border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
	padding-bottom: 15%;
	display: flex;
	align-items: stretch;


	.overview-img {
		width: 40%;
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		.overview-main {
			width: 80%;
			margin-bottom: 5%;
			position: relative;

			img {
				width: 100%;
			}

			svg {
				position: absolute;
				right: -13%;
				bottom: 0;
				width: 20px;
				height: 20px;
				fill: $blue;
				cursor: pointer;
				z-index: 3;
			}
		}

		.overview-img-content {
			width: 20%;
			display: flex;
			position: absolute;
			bottom: -35%;

			img {
				width: 100%;
				box-shadow: $box-shadow;
				background: #fff;
				margin-right: 5%;
				cursor: pointer;
				padding: 5%;
			}
		}
	}

	.overview-text {
		width: 60%;
		display: flex;
		flex-direction: column;
		// margin-left: 5%;
		position: relative;

		h3 {
			color: $blue;
			font-size: 2.2vw;
			font-family: $font-3;
			margin-bottom: 2%;
			margin-top: 4%;
		}

		.tagline {
			font-weight: 300;
			font-size: 1.2vw;
			font-family: $font-4;
			color: rgba($color: #000, $alpha: 0.7);
			margin-bottom: 0;
			font-style: italic;
		}

		.content {
			line-height: 180%;
			color: rgba($color: #000, $alpha: 0.7);
			margin-bottom: 0;
			font-size: 1.1vw;
			flex-grow: 1;
		}

		button {
			background: $blue;
			color: #fff;
			// border-radius: 5px;
			border: none;
			padding: 3% 5%;
			font-size: 1.3vw;
			margin-top: 5%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: -35%;

			svg {
				width: 18px;
				height: 18px;
				fill: #fff;
			}
		}
	}
}

/*--------------------------------------------------------------
# Indications
--------------------------------------------------------------*/
.indications {
	border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
	padding-bottom: 2%;

	h3 {
		font-size: 2vw;
		color: $blue;
		font-family: $font-3;
	}

	.indications-content {
		display: flex;
		flex-wrap: wrap;

		.indications-card {
			width: calc(100% / 3.2);
			position: relative;
			margin: 1% 1% 1% 0;

			.indications-img {
				width: 100%;

				img {
					width: 100%;
				}
			}

			.indications-text {
				position: absolute;
				bottom: 2%;
				// padding: 5%;
				text-align: center;
				background: rgba($color: $blue, $alpha: 0.9);
				width: 100%;

				p {
					font-size: 1.1vw;
					line-height: 180%;
					font-family: $font-2;
					color: #fff;
					margin: 0;
					text-transform: capitalize;
					list-style-type: circle;
					padding: 5% 2%;
				}
			}

		}
	}
}

/*--------------------------------------------------------------
# Storage Instructions
--------------------------------------------------------------*/
.storage {
	border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);

	.safety-content {
		display: block !important;

		.safety-card {
			margin-bottom: 2% !important;

			.safety-img {
				border-radius: 0 !important;
				box-shadow: none !important;

				svg {
					width: 35px !important;
					height: 35px !important;
				}
			}
		}
	}
}

/*--------------------------------------------------------------
# Safety Information 
--------------------------------------------------------------*/
.safety {
	padding-bottom: 2%;

	h3 {
		font-size: 2vw;
		color: $blue;
		font-family: $font-3;
	}

	.safety-content {
		display: flex;
		flex-wrap: wrap;

		.safety-card {
			width: calc(100% / 2.1);
			position: relative;
			margin: 1%;
			display: flex;
			align-items: center;

			.safety-img {
				border-radius: 50%;
				box-shadow: $box-shadow;
				width: 55px;
				height: 50px;
				position: relative;
				margin-right: 5%;

				svg {
					width: 30px;
					height: 30px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.safety-text {
				width: 90%;

				p {
					font-size: 1.2vw;
					line-height: 150%;
					color: rgba($color: #000, $alpha: 0.7);
					font-family: $font-2;
				}
			}


		}
	}
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer {
	padding: 2% 2% 0 2%;
	background: rgb(112, 152, 183);
	background: linear-gradient(90deg, rgba(112, 152, 183, 1) 0%, rgba(36, 76, 96, 1) 56%);
	color: #fff;
	border-top: 2px solid #fff;

	.footer-body {
		display: flex;
		justify-content: center;
		align-items: stretch;

		.footer-about,
		.footer-links,
		.footer-contact,
		.footer-map {
			margin: 1%;
		}

		.footer-about {
			width: 40%;

			h2 {
				font-size: 1.4vw;
				text-transform: capitalize;
				font-weight: bold;
				letter-spacing: 1px;
				margin-bottom: 5%;
				margin-top: 0;
				font-family: $font-3;
				letter-spacing: 2px;

				span {
					font-size: 1.1vw;
					font-family: Georgia, 'Times New Roman', Times, serif;
					font-style: italic;
					letter-spacing: 0;
				}
			}

			p {
				font-size: 1vw;
				margin: 0;
				font-family: $font-4;
			}
		}

		.footer-links {
			width: 23%;

			h4 {
				font-size: 1.4vw;
				text-transform: capitalize;
				margin-bottom: 15%;
				margin-top: 0;
				font-family: $font-3;
				letter-spacing: 1px;
			}

			.footer-links-content {
				display: flex;
				flex-direction: column;

				a {
					font-size: 1.1vw;
					text-transform: capitalize;
					margin-bottom: 5%;
					color: inherit;
					display: flex;
					align-items: center;
					font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

					&:hover {
						color: #fff;
						transform: scale(1.1) translatex(15px);
						transition: .5s all ease;

						svg {
							fill: #fff;
						}
					}

					svg {
						width: 15px;
						height: 15px;


						fill: #fff;

					}
				}
			}
		}

		.footer-contact {
			width: 40%;

			.footer-follow {
				margin: 10% 0;


				.footer-follow-top {

					display: flex;
					justify-content: flex-start;
					align-items: center;

					a {
						margin-right: 3%;
						border: 1px solid #fff;
						padding: 2%;
						border-radius: 5px;
						width: 20px;
						height: 20px;
						position: relative;

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 15px;
							height: 15px;
							fill: #fff;
						}

						&:hover {
							background: #fff;
							transition: .2s all ease;
							border-color: #fff;

							svg {
								fill: $blue;
							}
						}
					}
				}
			}

			h4 {
				font-size: 1.4vw;
				text-transform: capitalize;
				margin-bottom: 7%;
				margin-top: 0;
				font-family: $font-3;
				letter-spacing: 1px;
			}

			.footer-contact-body {

				.footer-contact-content {
					display: flex;
					align-items: center;
					margin-bottom: 2%;

					&:hover {

						svg,
						a {
							color: #fff;
							transition: $transition;
							fill: #fff;
						}
					}

					.svg {
						margin-right: 5%;

						svg {

							width: 15px;
							height: 15px;
							fill: #fff;
						}
					}

					a {
						font-size: 1vw;
						text-transform: capitalize;
						font-family: $font-4;
					}
				}

			}


		}

		.footer-map {
			width: 35%;

			iframe {
				width: 100%;
				height: 100%;
				border: none;
			}
		}
	}

	.footer-copyright {
		border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
		padding: 2% 0;
		font-family: $font-4;

		p {
			font-size: 1.1vw;
			margin: 0;
			text-align: center;

			a {
				font-weight: bold;
			}
		}

	}
}


/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {
	.section-title {
		h2 {
			font-size: 7vw;
		}
	}

	header {

		.header-links {
			display: none;
		}

		.header-logo {
			width: 25%;
		}
	}

	.banner {
		.banner-shape {
			border: 250px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 250px solid #fff;
			top: 12%;
		}

		.banner-shape2 {
			border: 150px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 120px solid #fff;

		}

		.banner-text {
			margin-top: 15%;

			h1 {
				text-shadow: none;
				font-size: 8vw;
			}

			p {
				font-size: 2vw;
				width: 110%;
			}

			a {
				font-size: 2vw;
				width: 40%;
				border: 1px solid #fff;
			}
		}

		.banner-img {
			margin-top: 5%;

			.banner-img-content {
				width: 30%;
			}

			.img1 {
				top: -330%;
			}

			.img2 {
				top: -330%;
			}

			.img3 {
				right: 25%;
			}
		}
	}

	.products {
		.products-body {
			flex-wrap: wrap;

			.products-card {
				width: 100%;
				flex-direction: column;

				&:last-child {
					.products-img {
						img {
							width: 60%;

							&:hover {
								width: 60%;
							}
						}
					}
				}

				.products-img {
					height: 30vh;
					width: 100%;

					img {
						width: 60%;
					}

				}

				.products-text {
					width: 90%;

					h3 {
						font-size: 6vw;
					}

					p {
						font-size: 3.5vw;
					}

					.product-text-btn {
						margin-top: -10%;

						a {
							span {
								font-size: 3.5vw;
							}
						}
					}
				}
			}
		}
	}

	.order {
		.order-body {
			flex-wrap: wrap;

			img {
				width: 100%;
			}

			form {
				width: 100%;

				.input {
					input {
						font-size: 3.5vw;
						margin-bottom: 5%;
					}
				}

				select {
					font-size: 3.5vw;
					margin-bottom: 5%;
				}

				button {
					font-size: 3.5vw;
					font-weight: bold;
				}
			}
		}
	}

	// Pages
	.sidebar {
		display: none;
	}

	.main-body {
		width: 100%;
		padding: 5%;
		margin-right: 0;
	}

	.form {
		width: 80%;

		h3 {
			font-size: 5vw;
		}

		form {

			input,
			select,
			button {
				font-size: 3.5vw;
			}

			button {
				margin-top: 5%;
			}
		}
	}

	.product-zoom {
		width: 90%;
	}

	.overview {
		flex-direction: column;
		padding-bottom: 35%;

		.overview-img {
			width: 100%;
			flex-direction: row;
			justify-content: space-between;

			.overview-main {
				margin-top: 5%;

				svg {
					right: 0;
				}
			}

			.overview-img-content {
				width: 15%;
				flex-direction: column;
				top: 7%;
				right: 0;

				img {
					margin-right: 0;
					margin-bottom: 10%;
				}
			}
		}

		.overview-text {
			width: 100%;

			h3 {
				font-size: 5vw;
			}

			.tagline {
				font-size: 3vw;
				margin-top: 0;
			}

			.content {
				font-size: 3.5vw;
			}

			button {
				font-size: 3.5vw;
				bottom: -35%;
			}
		}
	}

	.inside-heading {
		.key-content {
			margin-bottom: 3%;

			p {
				font-size: 3.5vw;
			}
		}

		.benefits-content {
			ul {
				padding-left: 5%;

				li {
					font-size: 3.5vw;
					margin-bottom: 2%;
				}
			}
		}
	}

	.indications {
		h3 {
			font-size: 5vw;
		}

		.indications-content {
			flex-wrap: wrap;

			.indications-card {
				width: 100%;

				.indications-text p {
					font-size: 4vw;

				}
			}
		}
	}

	.safety {
		h3 {
			font-size: 5vw;
		}

		.safety-content {
			.safety-card {
				width: 100%;

				.safety-img {
					width: 40px;
					height: 40px;

					svg {
						width: 25px;
						height: 25px;
					}
				}

				.safety-text {
					p {
						font-size: 3.5vw;
					}

				}
			}
		}
	}



	footer {
		.footer-body {
			// flex-direction: column;
			flex-wrap: wrap;
			padding: 5%;

			.footer-about,
			.footer-links,
			.footer-contact,
			.footer-map {
				width: 100%;
			}

			.footer-about {
				margin-bottom: 5%;

				h2 {
					font-size: 3.5vw;
					margin-bottom: 2%;

					span {
						font-size: 3vw;
					}
				}

				p {
					font-size: 3vw;
				}
			}

			.footer-links {
				padding-left: 0;
				margin-bottom: 2%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-links-content {
					a {
						font-size: 3.5vw;

						svg {
							width: 17px;
							height: 17px;
						}
					}
				}

			}

			.footer-contact {
				margin-bottom: 5%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 3.5vw
						}

						.svg {

							svg {
								width: 17px;
								height: 17px;
							}
						}
					}
				}

				.footer-follow {
					.footer-follow-top {
						a {
							width: 25px;
							height: 25px;

							svg {
								width: 18px;
								height: 18px;
							}
						}
					}
				}
			}
		}

		.footer-copyright {
			display: block;
			padding: 5% 0;

			p {
				text-align: center;

				font-size: 3vw;
				width: 100%;
			}
		}
	}
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
	.section-title {
		h2 {
			font-size: 7vw;
		}
	}

	header {

		.header-links {
			display: none;
		}

		.header-logo {
			width: 25%;
		}
	}

	.banner {
		.banner-shape {
			border: 500px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 500px solid #fff;
			top: 12%;
		}

		.banner-shape2 {
			border: 150px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 120px solid #fff;

		}

		.banner-text {
			margin-top: 15%;

			p {
				font-size: 2vw;
			}

			a {
				font-size: 2vw;
				width: 40%;
				border: 1px solid #fff;
			}
		}

		.banner-img {
			margin-top: 5%;
			width: 60%;

			.img1 {
				top: -300%;
			}

			.img2 {
				top: -300%;
			}
		}
	}

	.products {
		.products-body {
			flex-wrap: wrap;

			.products-card {
				width: 100%;
				flex-direction: column;

				&:last-child {
					.products-img {
						img {
							width: 60%;

							&:hover {
								width: 60%;
							}
						}
					}
				}

				.products-img {
					height: 45vh;
					width: 100%;
					background: $blue;

					img {
						width: 60%;

					}

				}

				.products-text {
					width: 90%;

					h3 {
						font-size: 6vw;
					}

					p {
						font-size: 3.5vw;
					}

					.product-text-btn {

						a {
							span {
								font-size: 3.5vw;
							}
						}
					}
				}
			}
		}
	}

	.order {
		.order-body {
			flex-wrap: wrap;

			img {
				width: 100%;
			}

			form {
				width: 100%;

				.input {
					input {
						font-size: 3.5vw;
						margin-bottom: 5%;
					}
				}

				select {
					font-size: 3.5vw;
					margin-bottom: 5%;
				}

				button {
					font-size: 3.5vw;
					font-weight: bold;
				}
			}
		}
	}

	// Pages

	.sidebar {
		display: none;
	}

	.main-body {
		width: 100%;
		padding: 5%;
		margin-right: 0;
	}

	.form {
		width: 80%;

		h3 {
			font-size: 5vw;
		}

		form {

			input,
			select,
			button {
				font-size: 3.5vw;
			}

			button {
				margin-top: 5%;
			}
		}
	}

	.product-zoom {
		width: 90%;
	}

	.overview {
		flex-direction: column;
		padding-bottom: 20%;

		.overview-img {
			width: 100%;
			flex-direction: row;
			justify-content: space-between;

			.overview-main {
				margin-top: 5%;

				svg {
					right: 5%;
				}
			}

			.overview-img-content {
				top: 0;
				right: 0;
				flex-direction: column;

				img {
					margin-right: 0;
					margin-bottom: 5%;
				}
			}
		}

		.overview-text {
			width: 100%;

			h3 {
				font-size: 5vw;
			}

			.tagline {
				font-size: 2.5vw;
			}

			.content {
				font-size: 3.5vw;
			}

			button {
				font-size: 3.5vw;
				bottom: -25%;
			}
		}
	}

	.inside-heading {
		.key-content {
			margin-bottom: 3%;

			p {
				font-size: 3.5vw;
			}
		}

		.benefits-content {
			ul {
				padding-left: 5%;

				li {
					font-size: 3.5vw;
					margin-bottom: 2%;
				}
			}
		}
	}

	.indications {
		h3 {
			font-size: 5vw;
		}

		.indications-content {
			flex-wrap: wrap;

			.indications-card {
				width: calc(100%/2.1);

				.indications-text p {
					font-size: 2.5vw;

				}
			}
		}
	}

	.safety {
		h3 {
			font-size: 5vw;
		}

		.safety-content {
			.safety-card {
				width: 100%;

				.safety-text {
					p {
						font-size: 3vw;
					}

				}
			}
		}
	}

	

	footer {
		.footer-body {
			// flex-direction: column;
			flex-wrap: wrap;
			padding: 5%;

			.footer-about,
			.footer-links,
			.footer-contact,
			.footer-map {
				width: 100%;
			}

			.footer-about {
				margin-bottom: 5%;

				h2 {
					font-size: 3vw;
					margin-bottom: 2%;

					span {
						font-size: 2.5vw;
					}
				}

				p {
					font-size: 3vw;
				}
			}

			.footer-links {
				padding-left: 0;
				margin-bottom: 2%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-links-content {
					a {
						font-size: 3.5vw;

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}

			}

			.footer-contact {
				margin-bottom: 5%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 3.5vw
						}

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}

				.footer-follow {
					.footer-follow-top {
						a {
							width: 28px;
							height: 28px;

							svg {
								width: 22px;
								height: 22px;
							}
						}
					}
				}
			}
		}

		.footer-copyright {
			display: block;
			padding: 5% 0;

			p {
				text-align: center;

				font-size: 3vw;
				width: 100%;
			}

		}
	}
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
	.section-title {
		h2 {
			font-size: 7vw;
		}
	}

	header {

		.header-links {
			display: none;
		}

		.header-logo {
			width: 25%;
		}
	}

	.banner {
		.banner-shape {
			border: 300px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 300px solid #fff;
			top: 12%;
		}

		.banner-shape2 {
			border: 150px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 120px solid #fff;

		}

		.banner-text {
			margin-top: 15%;

			p {
				font-size: 2vw;
			}

			a {
				font-size: 2vw;
				width: 40%;
				border: 1px solid #fff;
			}
		}

		.banner-img {
			margin-top: 5%;
			width: 60%;

			.img1 {
				top: -300%;
			}

			.img2 {
				top: -300%;
			}
		}
	}

	.products {
		.products-body {
			flex-wrap: wrap;

			.products-card {
				width: 100%;
				flex-direction: column;

				&:last-child {
					.products-img {
						img {
							width: 60%;

							&:hover {
								width: 60%;
							}
						}
					}
				}

				.products-img {
					height: 35vh;
					width: 100%;

					img {
						width: 60%;
					}

				}

				.products-text {
					width: 90%;

					h3 {
						font-size: 6vw;
					}

					p {
						font-size: 3.5vw;
					}

					.product-text-btn {

						a {
							span {
								font-size: 3.5vw;
							}
						}
					}
				}
			}
		}
	}

	.order {
		.order-body {
			flex-wrap: wrap;

			img {
				width: 100%;
			}

			form {
				width: 100%;

				.input {
					input {
						font-size: 3.5vw;
						margin-bottom: 5%;
					}
				}

				select {
					font-size: 3.5vw;
					margin-bottom: 5%;
				}

				button {
					font-size: 3.5vw;
					font-weight: bold;
				}
			}
		}
	}

	// Pages

	.sidebar {
		display: none;
	}

	.main-body {
		width: 100%;
		padding: 5%;
		margin-right: 0;
	}

	.form {
		width: 80%;

		h3 {
			font-size: 5vw;
		}

		form {

			input,
			select,
			button {
				font-size: 3.5vw;
			}

			button {
				margin-top: 5%;
			}
		}
	}

	.product-zoom {
		width: 90%;
	}

	.overview {
		flex-direction: column;
		padding-bottom: 20%;

		.overview-img {
			width: 100%;
			flex-direction: row;
			justify-content: space-between;

			.overview-main {
				margin-top: 5%;

				svg {
					right: 5%;
				}
			}

			.overview-img-content {
				flex-direction: column;
				top: 0;
				right: 0;

				img {
					margin-right: 0;
					margin-bottom: 5%;
				}
			}
		}

		.overview-text {
			width: 100%;

			h3 {
				font-size: 5vw;
			}

			.tagline {
				font-size: 2.5vw;
			}

			.content {
				font-size: 3.5vw;
			}

			button {
				font-size: 3.5vw;
				bottom: -20%;
			}
		}
	}

	.inside-heading {
		.key-content {
			margin-bottom: 3%;

			p {
				font-size: 3.5vw;
			}
		}

		.benefits-content {
			ul {
				padding-left: 5%;

				li {
					font-size: 3.5vw;
					margin-bottom: 2%;
				}
			}
		}
	}

	.indications {
		h3 {
			font-size: 5vw;
		}

		.indications-content {
			flex-wrap: wrap;

			.indications-card {
				width: 100%;

				.indications-text p {
					font-size: 3.5vw;
				}
			}
		}
	}

	.safety {
		h3 {
			font-size: 5vw;
		}

		.safety-content {
			.safety-card {
				width: 100%;

				.safety-img {
					width: 40px;
					height: 40px;

					svg {
						width: 25px;
						height: 25px;
					}
				}

				.safety-text {
					p {
						font-size: 3.5vw;
					}

				}
			}
		}
	}


	footer {
		.footer-body {
			// flex-direction: column;
			flex-wrap: wrap;
			padding: 5%;

			.footer-about,
			.footer-links,
			.footer-contact,
			.footer-map {
				width: 100%;
			}

			.footer-about {
				margin-bottom: 5%;

				h2 {
					font-size: 3vw;
					margin-bottom: 2%;

					span {
						font-size: 2.5vw;
					}
				}

				p {
					font-size: 3vw;
				}
			}

			.footer-links {
				padding-left: 0;
				margin-bottom: 2%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-links-content {
					a {
						font-size: 3.5vw;

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}

			}

			.footer-contact {
				margin-bottom: 5%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 3.5vw
						}

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}

				.footer-follow {
					.footer-follow-top {
						a {
							width: 28px;
							height: 28px;

							svg {
								width: 22px;
								height: 22px;
							}
						}
					}
				}
			}
		}

		.footer-copyright {
			display: block;
			padding: 5% 0;

			p {
				text-align: center;

				font-size: 3vw;
				width: 100%;
			}

		}
	}
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

	header {

		.menu {
			display: none;
		}

		.header-links {
			width: 70%;

			.h-l {
				font-size: 1.5vw;
			}

			.prod {
				p {
					font-size: 1.5vw;
				}

				.prod-dropdown {
					.prod-link {
						font-size: 1.5vw;
					}
				}
			}
		}
	}

	.banner {
		.banner-shape {
			border: 300px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 300px solid #fff;
			top: 12%;
		}

		.banner-shape2 {
			border: 150px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 120px solid #fff;

		}

		.banner-text {
			margin-top: 15%;

			p {
				font-size: 2vw;
			}

			a {
				font-size: 2vw;
				width: 40%;
				border: 1px solid #fff;
			}
		}

		.banner-img {
			margin-top: 5%;
		}
	}

	.products {
		.products-body {
			flex-wrap: wrap;

			.products-card {
				width: 100%;
				flex-direction: row;
				position: relative;

				.products-img {
					// height: 70vh;
					// height: 100%;
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 100%;
						margin: auto;
						display: block;
						// position: absolute;
						// top: 50%;
						// left: 50%;
						// transform: translate(-50%, -50%);
					}
				}

				.products-text {
					width: 50%;
					height: 79%;

					h3 {
						font-size: 4vw;
					}

					p {
						font-size: 2vw;
					}

					.product-text-btn {

						a {
							span {
								font-size: 2vw;
							}
						}
					}
				}
			}
		}
	}

	// Pages

	.sidebar {
		top: 7%;
		width: 25%;
		height: 85vh;

		a {
			font-size: 2vw;
			margin-bottom: 12%;

			&:first-child {
				margin-top: 5%;
			}
		}
	}

	.main-body {
		width: 70%;
		padding-left: 2%;
		margin-right: 0;
	}

	.product-zoom {
		width: 70%;
		border: 2px solid #fff;
	}

	.overview {
		flex-direction: column;
		padding-bottom: 20%;

		.overview-img {
			width: 100%;
			flex-direction: row;
			justify-content: space-between;

			.overview-main {
				margin-top: 5%;

				svg {
					right: 7%;
				}
			}

			.overview-img-content {
				top: 0;
				flex-direction: column;
				right: 0;

				img {
					margin-right: 0;
					margin-bottom: 5%;
				}
			}
		}

		.overview-text {
			width: 100%;

			h3 {
				font-size: 3vw;
			}

			.tagline {
				margin-top: 0;
				font-size: 1.5vw;
			}

			.content {
				font-size: 2vw;
			}

			button {
				font-size: 2vw;
				bottom: -30%;
			}
		}
	}

	.inside-heading {
		.key-content {
			margin-bottom: 3%;

			p {
				font-size: 2vw;
			}
		}

		.benefits-content {
			ul {
				padding-left: 5%;

				li {
					font-size: 2vw;
					margin-bottom: 2%;
				}
			}
		}
	}

	.indications {
		h3 {
			font-size: 3vw;
		}

		.indications-content {
			flex-wrap: wrap;

			.indications-card {
				width: calc(100%/3.2);

				.indications-text p {
					font-size: 1.6vw;

				}
			}
		}
	}

	.safety {
		h3 {
			font-size: 3vw;
		}

		.safety-content {
			.safety-card {
				width: 100%;

				.safety-text {
					p {
						font-size: 2vw;
					}

				}
			}
		}
	}

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {


	header {

		.menu {
			display: none;
		}

		.header-links {
			width: 70%;

			.h-l {
				font-size: 1.5vw;
			}

			.prod {
				p {
					font-size: 1.5vw;
				}

				.prod-dropdown {
					.prod-link {
						font-size: 1.5vw;
					}
				}
			}
		}
	}

	.banner {
		.banner-shape {
			border: 300px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 300px solid #fff;
			top: 12%;
		}

		.banner-shape2 {
			border: 150px solid transparent;
			border-top: 0;
			border-left: 1px solid #fff;
			border-bottom: 120px solid #fff;

		}

		.banner-text {
			margin-top: 15%;

			p {
				font-size: 2vw;
			}

			a {
				font-size: 2vw;
				width: 40%;
				border: 1px solid #fff;
			}
		}

		.banner-img {
			margin-top: 5%;
		}
	}

	.products {
		.products-body {
			flex-wrap: wrap;

			.products-card {
				width: 100%;
				flex-direction: row;
				position: relative;

				.products-img {
					height: 56vh;
					// height: 100%;
					width: 50%;

					img {
						width: 100%;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.products-text {
					width: 50%;

					h3 {
						font-size: 4vw;
					}

					p {
						font-size: 2vw;
					}

					.product-text-btn {
						a {
							span {
								font-size: 2vw;
							}
						}
					}
				}
			}
		}
	}

	.order {
		.order-body {
			flex-wrap: wrap;

			img {
				width: 100%;
			}

			form {
				width: 100%;

				.input {
					input {
						font-size: 2.5vw;
					}
				}

				select {
					font-size: 2.5vw;
				}

				button {
					font-size: 3vw;
					font-weight: bold;
				}
			}
		}
	}

	// Pages

	.sidebar {
		top: 5%;
		width: 25%;
		height: 85vh;

		a {
			font-size: 2vw;
			margin-bottom: 12%;

			&:first-child {
				margin-top: 5%;
			}
		}
	}

	.main-body {
		width: 70%;
		padding-left: 2%;
		margin-right: 0;
	}

	.inside-heading {
		.key-content {
			margin-bottom: 3%;

			p {
				font-size: 2vw;
			}
		}

		.benefits-content {
			ul {
				padding-left: 5%;

				li {
					font-size: 2vw;
					margin-bottom: 2%;
				}
			}
		}
	}

	.indications {
		h3 {
			font-size: 3vw;
		}

		.indications-content {
			flex-wrap: wrap;

			.indications-card {
				width: calc(100%/3.2);

				.indications-text p {
					font-size: 1.6vw;

				}
			}
		}
	}

	.safety {
		h3 {
			font-size: 3vw;
		}

		.safety-content {
			.safety-card {
				width: 100%;

				.safety-text {
					p {
						font-size: 2vw;
					}

				}
			}
		}
	}

	
}

@media (min-width: 1025px) and (max-width: $xl) {
	header {
		.menu {
			display: none;
		}
	}
}